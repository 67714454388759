import { CloseRounded, MenuRounded } from '@mui/icons-material'
import {
  AppBar,
  Dialog,
  IconButton,
  Slide, Stack, Toolbar,
  useTheme,
} from '@mui/material'
import type { TransitionProps } from '@mui/material/transitions'
import { LinkEx } from '@xylabs/react-link'
import * as React from 'react'

import { XYOIconColor, XYOTextColor } from '../../img/all-images/index.ts'
import { useNavbarData } from '../NavData/index.ts'
import { MobileNavSectionDialog } from './MobileNavSectionDialog.tsx'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />
})

export const MobileNavDialog: React.FC = () => {
  const theme = useTheme()
  const NavbarData = useNavbarData()

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <IconButton aria-label="menu-button" onClick={handleClickOpen}>
        <MenuRounded fontSize="large" />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.paper, backgroundImage: 'none', paddingLeft: 2, paddingRight: 2,
          },
        }}
      >
        <AppBar
          sx={{
            position: 'relative', paddingY: 1, marginBottom: 2,
          }}
          color="transparent"
          elevation={0}
        >
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            <LinkEx to="/" onClick={handleClose}>
              <Stack direction="row" paddingRight={1} spacing={0.5} alignItems="center" justifyContent="center">
                <img src={XYOIconColor} height={40} alt="XYO Icon Logo" />
                <img src={XYOTextColor} height={24} alt="XYO Text Logo" />
              </Stack>
            </LinkEx>
            <IconButton aria-label="menu-button" onClick={handleClose}>
              <CloseRounded fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Stack gap={3} flexDirection="column" alignItems="flex-start">
          {NavbarData.map((item, index) => {
            return (
              <MobileNavSectionDialog key={index} closeAllDialogs={handleClose} {...item} />
            )
          })}
        </Stack>
      </Dialog>
    </React.Fragment>
  )
}
