import {
  Container, Typography, useTheme,
} from '@mui/material'
import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EmailNewsletterTextField } from './EmailNewsletterTextField.tsx'

export const Newsletter: React.FC<BusyBoxProps> = () => {
  const { t } = useTranslation()
  return (
    <SectionSpacingRow marginTop={10} style={{ background: 'linear-gradient(to bottom right,rgb(85, 137, 248), #5560f8,#2b1bbf)' }}>
      <Container>
        <FlexGrowCol>
          <Typography textAlign="center" gutterBottom variant="h6">
            {t('footer:newsletter.supertitle')}
          </Typography>
          <Typography paddingBottom={3} gutterBottom textAlign="center" variant="h2">
            {t('footer:newsletter.title')}
          </Typography>
          <EmailNewsletterTextField />
        </FlexGrowCol>
      </Container>
    </SectionSpacingRow>
  )
}
