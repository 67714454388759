import { QuestionAnswerRounded } from '@mui/icons-material'
import { t } from 'i18next'
import React, { useEffect } from 'react'

import type { ButtonSectionProps } from '../BasicHero/index.ts'
import { SubLinkSection, XyoBasicHero } from '../BasicHero/index.ts'
import { XyoBasePage } from '../XyoBasePage.tsx'

export interface Four04PageProps {
  custom404Buttons?: ButtonSectionProps
  custom404Title?: string
}

export const Four04Page: React.FC<Four04PageProps> = ({ custom404Title, custom404Buttons }) => {
  useEffect(() => {
    // Set HTTP status code to 404 for SEO purposes
    if (typeof globalThis !== 'undefined') {
      document.title = t('404:pageTitle')
      globalThis.history.replaceState(null, '', '/404')
      document.body.classList.add('error-404')
    }
    // This is important to ensure the 404 response is recognized by crawlers
    // Optionally, use the server-side mechanism to send the proper HTTP code in a SSR setup
  }, [])

  return (
    <XyoBasePage title={t('404:pageTitle')}>
      <XyoBasicHero
        alignContent="center"
        title={custom404Title ?? t('404:heroTitle')}
        desc={t('404:heroDesc')}
        button1Text="Take me home!"
        button1Props={{ to: '/' }}
        subLinkSection={(
          <SubLinkSection
            icon={<QuestionAnswerRounded />}
            href="https://support.xy.company/hc/en-us/requests/new"
            text1={t('404:sublink1')}
            text2={t('404:sublink2')}
          />
        )}
      />
    </XyoBasePage>
  )
}
