import {
  Facebook, Instagram, LinkedIn, Reddit, StorefrontRounded, Telegram, Twitter, YouTube,
} from '@mui/icons-material'
import {
  Grid2, Stack, Typography, useTheme,
} from '@mui/material'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import type { ReactNode } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import-x/no-internal-modules
import { FaDiscord } from 'react-icons/fa'

import { useSettings } from '../../contexts/index.ts'
import { XYOIconWhite, XYOTextWhite } from '../../img/all-images/index.ts'
import { RotationAnimation } from '../AnimatedComponents/index.ts'

interface socialLinksDataProps {
  icon: ReactNode
  link: string
}

const socialLinksData: socialLinksDataProps[] = [
  { icon: <Facebook style={{ fontSize: '24px' }} />, link: 'https://www.facebook.com/OfficialXYO' },
  { icon: <Twitter style={{ fontSize: '24px' }} />, link: 'https://twitter.com/OfficialXYO' },
  { icon: <Instagram style={{ fontSize: '24px' }} />, link: 'https://www.instagram.com/officialxyo/' },
  { icon: <Telegram style={{ fontSize: '24px' }} />, link: 'https://t.me/official_xyo' },
  { icon: <Reddit style={{ fontSize: '24px' }} />, link: 'https://www.reddit.com/r/XYONetwork/' },
  { icon: <YouTube style={{ fontSize: '24px' }} />, link: 'https://www.youtube.com/channel/UCyZDqb9pgntVHJVt1pxXtsw' },
  { icon: <LinkedIn style={{ fontSize: '24px' }} />, link: 'https://www.linkedin.com/company/officialxyo/' },
  { icon: <FaDiscord style={{ fontSize: '24px' }} />, link: 'https://discord.gg/officialxyo' },
  { icon: <StorefrontRounded style={{ fontSize: '24px' }} />, link: 'https://shop.xyo.network' },
]

export const SocialFooterLinks: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  const { darkMode } = useSettings()
  const { t } = useTranslation()
  return (
    <FlexCol sx={{ alignItems: { md: 'flex-start', xs: 'center' } }} {...props}>
      <LinkEx to="/" marginBottom={2}>
        <Stack direction="row" paddingRight={1} spacing={0.5} alignItems="center" justifyContent="center">
          <RotationAnimation rotation={20}>
            <img src={darkMode ? XYOIconWhite : XYOIconWhite} height={32} />
          </RotationAnimation>
          <img src={darkMode ? XYOTextWhite : XYOTextWhite} height={25} />
        </Stack>
      </LinkEx>
      <Typography fontWeight={500} variant="h6" sx={{ color: theme.palette.primary.contrastText }}>
        {t('footer:links.socials.title')}
      </Typography>
      <Grid2 container sx={{ justifyContent: { md: 'flex-start', xs: 'center' } }} spacing={2} paddingTop={1.5}>
        {socialLinksData.map((social, index) => {
          return (
            <Grid2
              key={index}
              size={{
                xs: 3, sm: 1, md: 4,
              }}
            >
              <FlexRow sx={{ alignItems: { md: 'flex-start', xs: 'center' }, justifyContent: { md: 'flex-start', xs: 'center' } }}>
                <RotationAnimation rotation={20}>
                  <LinkEx sx={{ color: theme.palette.primary.contrastText }} href={social.link}>
                    {social.icon}
                  </LinkEx>
                </RotationAnimation>
              </FlexRow>
            </Grid2>
          )
        })}
      </Grid2>
    </FlexCol>
  )
}
